@import './variables';
@import './typography';


//typography kendo variables
$font-size: $font-size-base;
$font-family: $default-font-family;
$line-height: $line-height-base;

//common kendo variables
$kendo-border-radius: 0rem;
$base-bg: $biz-white;
$base-text: $biz-black;
//$base-border: try-shade( $base-bg, 1 )
//$base-gradient: null;
$hovered-bg: $biz-blue;
$hovered-text: $biz-white;
//$hovered-border: try-shade( $hovered-bg, 1 );
//$hovered-gradient: null;
//$selected-bg: $primary;
//$selected-text: try-shade( $base-bg, 1 );
//$selected-border: try-shade( $selected-bg, 1 );
//$selected-gradient: null;

//kendo color palette
$primary:               $biz-blue;
$secondary:             $biz-blue;
$tertiary:              $biz-blue;
$info:                  $biz-cyan;
$success:               $biz-success;
$warining:              $biz-info;
$error:                 $biz-warning;

//kendo button variables
$kendo-button-bg:       $biz-blue;
$kendo-button-hover-bg: $biz-blue-dark;
$kendo-button-selected-bg: $biz-blue-dark;
$kendo-button-active-bg: $biz-blue-dark;

//kendo chip variables
$kendo-chip-base-bg:    $biz-blue;
$kendo-chip-solid-bg:   $biz-blue;
$kendo-chip-solid-text: $biz-white;
$kendo-chip-solid-focus-bg: $biz-blue-dark;
$kendo-chip-solid-hover-bg: $biz-blue-dark;
$kendo-chip-solid-selected-bg: $biz-blue-dark;

//grid kendo variables
$grid-text:		        $biz-black;	
$grid-border:		    $biz-white;
$grid-header-bg:		$biz-white;
$grid-header-text:      $biz-black;
$grid-header-border:    $biz-black;
$grid-alt-bg:           $biz-grey-light;
$grid-hovered-bg:       default;
$grid-hovered-border:   $biz-black;

//Kendo bootstrap theme
@import "~@progress/kendo-theme-bootstrap/dist/all.scss";
@import './kendo_v5';

html, body { 
    height: 100%; 
    font-family: $default-font-family;
    color: $default-text-color;
    font-weight: $font-weight-base;
    line-height: $line-height-base;
    font-size: $font-size-base;
}

body { 
    margin: 0; 
    background-color: $biz-beige-light;
}

.icon {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

.content-overlay {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: calc(100% - 10.5rem);
    top: 10.5rem;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.4;
    background-color: $biz-black; /* Black background with opacity */
    z-index: 50; /* Specify a stack order in case you're using a different order for other elements */
    cursor: default;
}

.header-overlay {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 10.5rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.0;
    background-color: $biz-black; /* Black background with opacity */
    z-index: 50; /* Specify a stack order in case you're using a different order for other elements */
    cursor: default;
}

.dialog-content {
    margin: 2rem;
}