/**
 * @license
 * MyFonts Webfont Build ID 3953257, 2020-09-28T06:41:12-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: NeueFrutigerWorld-Heavy by Monotype
 * URL: https://www.myfonts.com/fonts/mti/neue-frutiger-world/heavy/
 *
 * Webfont: NeueFrutigerWorld-Light by Monotype
 * URL: https://www.myfonts.com/fonts/mti/neue-frutiger-world/light/
 *
 *
 * Webfonts copyright: Copyright &amp;#x00A9; 2018 Monotype Imaging Inc. All rights reserved.
 *
 * © 2020 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
/* URL not working anymore. Comment out for css to work @import url("//hello.myfonts.net/count/3c5269"); */

@font-face {
  font-family: 'NeueFrutigerWorld';
  src:
    url('../assets/fonts/NeueFrutigerWorldLight.woff2') format('woff2'),
    url('../assets/fonts/NeueFrutigerWorldLight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: 'NeueFrutigerWorld';
  src:
    url('../assets/fonts/NeueFrutigerWorldHeavy.woff2') format('woff2'),
    url('../assets/fonts/NeueFrutigerWorldHeavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}
