@import './variables';

//Breadcrumb
.k-breadcrumb-root-link{
    color: inherit;
    text-decoration: inherit;
}

.k-breadcrumb-link {
    color: inherit;
    text-decoration: inherit;
}

.k-breadcrumb-last-item .k-breadcrumb-link {
    color: $biz-grey-dark;
}

.k-breadcrumb {
    color: $biz-black;
    font-size: $font-size-sm;
}

//Button
.k-button-solid-primary:hover, .k-button-solid-primary.k-hover {
    background-color: $biz-blue-dark;
}

//Dropdownlist
.k-dropdownlist {

    border-color: rgb(206, 212, 218);
    background-color: $biz-white;
    color: $biz-black;
}

.k-picker:focus, .k-picker.k-focus {
    box-shadow: none;
}

.k-picker:hover {
    border-color: rgb(206, 212, 218);
    background-color: $biz-white;
}

//Dropdown Selection Lists
.k-list-item {

    &.k-focus, &:focus {
        background-color: $biz-blue;
        color: $biz-white;
        box-shadow: none;
    }

    &.k-selected {
        &:hover, &:focus, &.k-focus{
            background-color: $biz-blue-dark;
        }
    }
}

//kendo grid
.k-grid {
    padding: 0 1rem 0 1rem;

    td {
        white-space: nowrap;
    }
}

th.k-header {
    border-bottom: 1px $biz-black solid;
}

.k-grid-remove-command, .k-grid-edit-command {
    background-color: transparent;
    border:none;
    color: $biz-black;
    &:hover {
        background-color: transparent;
    }
}

.k-grid-pager {
    background-color: $biz-beige-light;
    border: none;
    position: absolute;
    bottom: -5rem;
    display: flex;
    justify-content: center;
    width: 100%;

    > kendo-pager-prev-buttons {
        flex-grow: 1;
        text-align: right;
    }

    * {
        color: $biz-black;
    }

    .k-pager-nav {
        height: 3rem;
        width: 3rem;

        &:focus {
            box-shadow: none;
        }
    }

    .k-pager-numbers {
        
        .k-link {
            color: $biz-black;
            background-color: transparent;
            border: none;
            height: 3rem;
            margin: 0 1rem 0 1rem;

            &:hover {
                background-color: transparent;
                color: $biz-black;
            }

            &.k-state-selected {
                background-color: transparent;
                color: $biz-black;
                font-weight: 600;
            }

            &:focus {
                box-shadow: none;
            }
        }
    }
}

//Label
.k-form kendo-label, .k-form .k-label {
    margin-bottom: 0px;
}

//Multiselect
.k-multiselect {
    
    &.k-input:focus-within, &.k-input:focus{
        border-color: rgb(206, 212, 218);
        box-shadow: none;
    } 
}

//Panel bar
.k-panelbar > .k-item > .k-link {
    background-color: $biz-white;
    color: $biz-black;
    font-weight: 600;
    &:hover {
        background-color: $biz-white;
    }

    &.k-state-selected {
        background-color: $biz-white;
        color: $biz-black;

        &:hover {
            background-color: $biz-white;
        }
    }

    &.k-state-focused {
        box-shadow: none;
    }
}

.k-panelbar > .k-item.k-state-expanded {
    border: 1px solid $biz-black;
}


//k-upload
.k-dropzone-hint {
    margin-left: 0.375rem;
    text-align: center;
}