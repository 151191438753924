// TYPOGRAPHY
$default-font-family: 'NeueFrutigerWorld', sans-serif;
$default-text-color: #000;
$default-font-size: 1rem;

$font-weight-normal: 300;
$font-weight-bold: 900;

$font-size-base:              $default-font-size;
$font-size-lg:                ($font-size-base * 1.25) !default;
$font-size-sm:                ($font-size-base * .875) !default;

$font-weight-base:            $font-weight-normal;
$line-height-base:            1.5;

$h1-font-size:                $font-size-base * 2.25;
$h2-font-size:                $font-size-base * 2.0;
$h3-font-size:                $font-size-base * 1.75;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base * 1.0;

// COLORS
// BIZ-colors
$biz-white: #fff;
$biz-black: #000;
$biz-black-logo: #1d1d1b;
$biz-grey-dark: #787878;
$biz-grey-middle: #e4e4e4;
$biz-grey-light: #eeeded;
$biz-grey-lightest: #f1f1f1;
$biz-cyan: #0099cc;
$biz-blue: #356f92;
$biz-blue-dark: #11334b;
$biz-beige: #c6b686;
$biz-beige-light: #f7f8f6;
$biz-success: #3bab79;
$biz-info: #f2ab35;
$biz-warning: #ea3d51;